import React from 'react';
import type { FC } from 'react';
import { Link, graphql } from 'gatsby';
import { Layout } from '../components/layout';
// eslint-disable-next-line import/no-unresolved
import { OncologySurgeryPageQuery } from 'graphql-types';
import { SEO } from '../components/seo';

interface PageProps {
  data: OncologySurgeryPageQuery;
}

const Page: FC<PageProps> = ({ data }) => (
  <Layout>
    <SEO
      title="腫瘍外科"
      description="一般的な腫瘍外科は全て実施可能です。また高度な腫瘍外科も対応可能です。詳細はページ内をご覧ください。"
      keywords="腫瘍外科"
    />
    <article id="clinical-examination">
      <h2>腫瘍外科 - Oncology surgery -</h2>
      <div className="skew-border"></div>
      <section>
        <p>
          一般的な腫瘍外科は
          <span className="underline">全て実施可能です。</span>
          また以下のような特殊な腫瘍外科手術も対応可能です。
        </p>
        <ul>
          <li>・部分肝葉切除　完全肝葉切除　胆嚢摘出　膵臓部分切除</li>
          <li>・肺葉切除　胸腺腫摘出　心膜切除　永久気管開孔術　乳び胸</li>
          <li>・甲状腺癌摘出　猫の甲状腺腫摘出</li>
          <li>・腎臓摘出　副腎摘出　膀胱部分切除　膀胱全摘出+尿管移設</li>
          <li>・上顎下顎　顎骨切除　胃部分切除（ビルロート1型・2型）</li>
          <li>・胸腔アプローチによる食道腫瘤切除</li>
          <li>・骨盤切除アプローチによる直腸腫瘍切除</li>
          <li>・肛門嚢腺癌摘出+リンパ節切除</li>
          <li>・全耳道切除+外側鼓室胞骨切り</li>
          <li>・前立腺切除術</li>
        </ul>
      </section>
      <section>
        <h3>緊急手術 - Emergency surgery -</h3>
        <p>
          当院では下記のような
          <span className="underline">緊急手術にも対応いたします。</span>
        </p>
        <ul>
          <li>・脾臓破裂にともなう腹腔内出血　肝臓腫瘤からの出血</li>
          <li>・胆嚢摘出　胆嚢空腸吻合</li>
          <li>・卵巣腫瘍や子宮腫瘍による子宮破裂　子宮蓄膿症</li>
          <li>・肺葉捻転</li>
          <li>・会陰ヘルニア　膀胱脱の併発</li>
          <li>・直腸脱</li>
        </ul>
      </section>
    </article>
  </Layout>
);
export default Page;

export const query = graphql`
  query OncologySurgeryPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
